import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { staticRoutes, notFountRoute } from "@/router/appRoutes";
import { defaultAppLoginTitle } from "@/utils/constants/user";

// vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) {
    return originalPush.call(this, location, resolve, reject);
  }
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

export function reloadUserRoutes(succesCallback, failedCallback) {
  store
    .dispatch("user/reloadUserRoles")
    .then(permissionNames => {
      if (!permissionNames || permissionNames.length < 1) {
        throw "权限不足，登陆失败";
      }
      $_resetUserRoutes();
      // 动态加载 routers
      let existingRoutes = router.getRoutes();
      store
        .dispatch("permission/generateUserRoutes", {
          existingRoutes,
          permissionNames
        })
        .then(() => {
          let accessRoutes = store.getters["permission/dynamicUserRoutes"];
          for (let newRoute of accessRoutes) {
            router.addRoute(newRoute);
          }
          router.addRoute(notFountRoute);
          succesCallback && succesCallback();
        });
    })
    .catch(err => {
      failedCallback && failedCallback(err);
    });
}

function $_getNewVueRouter() {
  return new VueRouter({
    mode: "history",
    base: process.env.VUE_APP_PUBLIC_PATH,
    routes: staticRoutes,
    scrollBehavior() {
      return { x: 0, y: 0 };
    }
  });
}

const router = $_getNewVueRouter();

router.beforeEach((to, from, next) => {
  // title 配置
  let docTitle = "加载中";
  if (to.meta.type === "admin") {
    docTitle = "管理中心";
  } else if (to.meta.type === "sca") {
    docTitle = "测量中心";
  } else {
    docTitle = to.meta.title;
  }
  document.title = `${store.getters["user/customerName"] ||
    defaultAppLoginTitle}-${docTitle}`;
  // 对于 admin 的路由控制
  if (to.meta.type === "admin") {
    if (from.name !== "login" && !store.getters["user/regionGuid"]) {
      // 无登录跳转
      next({ name: "login" });
    } else {
      // 已登录，获取 permissions
      let permissionNames = store.getters["user/permissions"];
      // 登录界面无需获取 permissions
      if (permissionNames.length < 1 && to.name !== "login") {
        reloadUserRoutes(
          () => {
            next({ ...to, replace: true });
          },
          () => {
            next({ name: "login" });
          }
        );
      } else {
        next();
      }
    }
  } else if (to.meta.type === "sca") {
    // scalogin 与 screport 页面不用校验是否已登录
    if (
      to.name !== "scalogin" &&
      to.name !== "scareport" &&
      !store.getters["sca/regionGuid"]
    ) {
      next({ name: "scalogin" });
    } else {
      next();
    }
  } else if (!to.name) {
    // 未找到任何路由时，name为空
    reloadUserRoutes(
      () => {
        next({ ...to, replace: true });
      },
      () => {
        next({ name: "login" });
      }
    );
  } else {
    next();
  }
});

function $_resetUserRoutes() {
  let newRouter = $_getNewVueRouter();
  router.matcher = newRouter.matcher;
}

export default router;
