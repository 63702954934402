import { getRolePermissionFromUser } from "@/api/role";

const _defaultNaviTitle = {
  caseList: "案例",
  personList: "被试人员",
  node: "测评单位",
  lbGroup: "测评",
  lbGroupGroup: "团体测评",
  lbGroupOpen: "开放测评",
  lbGroupIndv: "个人测评",
  lbInfo: "量表",
  lbInfoManage: "量表设置",
  lbInfoAlert: "心理危机预警",
  loginSettings: "登录设置",
  appointPages: "预约",
  processAppoint: "待处理的预约",
  doAppoint: "预约管理",
  appointHistory: "预约处理历史",
  taskPages: "任务",
  processTask: "接收的任务",
  createdTask: "创建的任务",
  lbInfoDocs: "量表文档",
  lbDefaultTemp: "默认设置",
  lbGroupPattern: "量表套餐"
};

export default {
  namespaced: true,
  state: {
    regionGuid: "",
    dispName: "",
    userGuid: "",
    userName: "",
    naviTitle: {},
    loginNodeGuids: [],
    isSuperNode: false,
    roleGuid: "",
    permissions: [],
    customerUrl: "",
    customerName: ""
  },

  getters: {
    customerName(state) {
      return state.customerName || sessionStorage.getItem("customerName") || "";
    },
    customerUrl(state) {
      return state.customerUrl || sessionStorage.getItem("customerUrl") || "";
    },
    regionGuid(state) {
      return state.regionGuid || sessionStorage.getItem("regionGuid") || "";
    },
    userDispName(state) {
      return state.dispName || sessionStorage.getItem("userDispName") || "";
    },
    userGuid(state) {
      return state.userGuid || sessionStorage.getItem("userGuid") || "";
    },
    naviTitle(state) {
      let storedNaviTitle = state.naviTitle;
      if (!storedNaviTitle || Object.keys(storedNaviTitle).length < 1) {
        let sessionNaviTitle = sessionStorage.getItem("naviTitle");
        storedNaviTitle = sessionNaviTitle ? JSON.parse(sessionNaviTitle) : {};
      }
      // 只替换修改过的部分
      return Object.assign({ ..._defaultNaviTitle }, storedNaviTitle);
    },
    loginNodeGuids(state) {
      if (!state.loginNodeGuids || state.loginNodeGuids.length < 1) {
        var storedNodeGuids = sessionStorage.getItem("nodeGuids");
        return storedNodeGuids ? JSON.parse(storedNodeGuids) : [];
      }
      return state.loginNodeGuids;
    },
    userName(state) {
      return state.userName || sessionStorage.getItem("userName") || "";
    },
    isSuperNode(state) {
      return state.isSuperNode || false;
    },
    roleGuid(state) {
      return state.roleGuid || "";
    },
    permissions(state) {
      return state.permissions || [];
    }
  },

  actions: {
    setLoginInfo(
      { commit },
      {
        regionGuid,
        userGuid,
        userName,
        loginNodeGuids,
        customerUrl,
        customerName
      }
    ) {
      commit("SET_REGION_GUID", regionGuid);
      commit("SET_USER_GUID", userGuid);
      commit("SET_USER_NAME", userName);
      commit("SET_LOGIN_NODE_GUIDS", loginNodeGuids || []);
      commit("SET_CUSTOMER_URL", customerUrl);
      commit("SET_CUSTOMER_NAME", customerName);
    },
    clearLoginInfo({ commit }) {
      commit("SET_REGION_GUID", null);
      commit("SET_DISP_NAME", null);
      commit("SET_USER_GUID", null);
      commit("SET_USER_NAME", null);
      commit("SET_LOGIN_NODE_GUIDS", []);
      commit("SET_ROLE_GUID", null);
      commit("SET_PERMISSIONS", []);
      commit("SET_CUSTOMER_URL", null);
      commit("SET_CUSTOMER_NAME", null);
    },
    setUserDispName({ commit }, userDispName) {
      commit("SET_DISP_NAME", userDispName);
    },
    setNaviTitle({ commit }, naviTitle) {
      commit("SET_NAVI_TITLE", naviTitle);
    },
    async reloadUserRoles({ commit, getters }) {
      try {
        let userRole = await getRolePermissionFromUser(getters.userGuid);
        let permissionNames =
          !!userRole.permissions && userRole.permissions.length > 0
            ? userRole.permissions.map(p => p.name)
            : [];
        commit("SET_IS_SUPER_NODE", userRole.isSuper);
        commit("SET_ROLE_GUID", userRole.guid);
        commit("SET_PERMISSIONS", permissionNames);
        return permissionNames;
      } catch {
        throw "获取用户权限失败，请联系管理员设置账号权限";
      }
    }
  },

  mutations: {
    SET_CUSTOMER_NAME(state, customerName) {
      state.customerName = customerName;
      sessionStorage.setItem("customerName", customerName || "");
    },
    SET_CUSTOMER_URL(state, customerUrl) {
      state.customerUrl = customerUrl;
      sessionStorage.setItem("customerUrl", customerUrl || "");
    },
    SET_REGION_GUID(state, regionGuid) {
      state.regionGuid = regionGuid;
      sessionStorage.setItem("regionGuid", regionGuid || "");
    },
    SET_DISP_NAME(state, dispName) {
      state.dispName = dispName;
      sessionStorage.setItem("userDispName", dispName || "");
    },
    SET_USER_GUID(state, userGuid) {
      state.userGuid = userGuid;
      sessionStorage.setItem("userGuid", userGuid || "");
    },
    SET_USER_NAME(state, userName) {
      state.userName = userName;
      sessionStorage.setItem("userName", userName || "");
    },
    SET_NAVI_TITLE(state, naviTitle) {
      state.naviTitle = naviTitle;
      sessionStorage.setItem("naviTitle", JSON.stringify(naviTitle));
    },
    SET_LOGIN_NODE_GUIDS(state, loginNodeGuids) {
      state.loginNodeGuids = [...loginNodeGuids];
      sessionStorage.setItem("nodeGuids", JSON.stringify(loginNodeGuids));
    },
    SET_IS_SUPER_NODE(state, isSuperNode) {
      state.isSuperNode = isSuperNode;
    },
    SET_ROLE_GUID(state, roleGuid) {
      state.roleGuid = roleGuid;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  }
};
