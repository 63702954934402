import { permissioNames } from "@/utils/constants/user";
import store from "@/store";

export const notFountRoute = {
  path: "*",
  name: "nopage",
  meta: {
    type: "nopage",
    title: "对不起，您没有权限进行这个操作"
  },
  component: () => import(/* webpackChunkName: "nopage" */ "@/views/NoPage.vue")
};

export const staticRoutes = [
  {
    path: "/",
    name: "login",
    meta: {
      type: "adminlogin",
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/noreg",
    name: "noreg",
    props: true,
    meta: {
      type: "noreg",
      title: "未注册"
    },
    component: () => import(/* webpackChunkName: "noreg" */ "@/views/NoReg.vue")
  },
  {
    path: "/errorpage",
    name: "errorpage",
    props: true,
    meta: {
      type: "errorpage",
      title: "页面错误"
    },
    component: () =>
      import(/* webpackChunkName: "errorpage" */ "@/views/ErrorPage.vue")
  },
  {
    path: "/:customerUrl?/lgscreen",
    name: "lgscreen",
    props: true,
    meta: {
      type: "lgscreen",
      title: "大屏展示",
      perms: [permissioNames.admin_data_dashboard.value]
    },
    component: () =>
      import(
        /* webpackChunkName: "lgscreen" */ "@/views/chart/LargeScreenPage.vue"
      )
  },
  {
    path: "/:customerUrl",
    name: "customerlogin",
    meta: {
      type: "adminlogin",
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  }
];

export const dynamicRoutes = [
  {
    path: "/admin",
    name: "admin",
    meta: {
      type: "admin"
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/Admin.vue"),
    children: [
      {
        path: "chart",
        name: "chart",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-chart-box",
            title: "数据看板"
          },
          perms: [permissioNames.admin_data_dashboard.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "report" */
            "@/views/admin/AdminChart.vue"
          )
      },
      {
        path: "report",
        name: "report",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-text-box-search",
            title: () => store.getters["user/naviTitle"].caseList
          },
          perms: [
            permissioNames.case_read.value,
            permissioNames.case_edit.value,
            permissioNames.case_export.value,
            permissioNames.case_grouprpt_read.value,
            permissioNames.case_import.value,
            permissioNames.case_rpt_edit.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "report" */
            "@/views/admin/AdminCaseReport.vue"
          )
      },
      {
        path: "testperson",
        name: "testperson",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-account-multiple",
            title: () => store.getters["user/naviTitle"].personList
          },
          perms: [
            permissioNames.person_edit.value,
            permissioNames.person_read.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "testperson" */
            "@/views/admin/AdminTestPerson.vue"
          )
      },
      {
        path: "node",
        name: "confignode",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-layers",
            title: () => store.getters["user/naviTitle"].node
          },
          perms: [
            permissioNames.node_edit.value,
            permissioNames.node_read.value,
            permissioNames.node_tstctrl.value,
            permissioNames.node_rpt_sign.value,
            permissioNames.node_fieldconfig.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "confignode" */
            "@/views/admin/AdminConfigNode.vue"
          )
      },
      {
        path: "config",
        name: "configbase",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-pencil-box-multiple",
            title: () => store.getters["user/naviTitle"].lbGroup
          },
          perms: [
            permissioNames.group_sca_edit.value,
            permissioNames.group_sca_read.value,
            permissioNames.open_sca_edit.value,
            permissioNames.open_sca_read.value,
            permissioNames.indv_sca_edit.value,
            permissioNames.indv_sca_read.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "configbase" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "grouplbgroup",
            name: "grouplbgroup",
            props: () => ({ type: "group" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-account-multiple-outline",
                title: () => store.getters["user/naviTitle"].lbGroupGroup
              },
              perms: [
                permissioNames.group_sca_edit.value,
                permissioNames.group_sca_read.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "grouplbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          },
          {
            path: "openlbgroup",
            name: "openlbgroup",
            props: () => ({ type: "open" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-earth",
                title: () => store.getters["user/naviTitle"].lbGroupOpen
              },
              perms: [
                permissioNames.open_sca_edit.value,
                permissioNames.open_sca_read.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "openlbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          },
          {
            path: "individuallbgroup",
            name: "individuallbgroup",
            props: () => ({ type: "individual" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-account-outline",
                title: () => store.getters["user/naviTitle"].lbGroupIndv
              },
              perms: [
                permissioNames.indv_sca_edit.value,
                permissioNames.indv_sca_read.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "individuallbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          }
        ]
      },

      {
        path: "lb",
        name: "lbmanage",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-clipboard-text-multiple-outline",
            title: () => store.getters["user/naviTitle"].lbInfo
          },
          perms: [
            permissioNames.lb_read.value,
            permissioNames.lb_edit.value,
            permissioNames.lb_doc_read.value,
            permissioNames.lb_alert_read.value,
            permissioNames.lb_alert_edit.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "lbmanage" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "lbinfomanage",
            name: "lbinfomanage",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-card-bulleted-settings-outline",
                title: () => store.getters["user/naviTitle"].lbInfoManage
              },
              perms: [
                permissioNames.lb_read.value,
                permissioNames.lb_edit.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbinfomanage" */
                "@/views/admin/AdminLbInfoManage.vue"
              )
          },
          {
            path: "lbwaring",
            name: "lbwaring",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-alert-plus-outline",
                title: () => store.getters["user/naviTitle"].lbInfoAlert
              },
              perms: [
                permissioNames.lb_alert_read.value,
                permissioNames.lb_alert_edit.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbwaring" */
                "@/views/admin/AdminLbWaringManage.vue"
              )
          },
          {
            path: "lbdocs",
            name: "lbdocs",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-file-search-outline",
                title: () => store.getters["user/naviTitle"].lbInfoDocs
              },
              perms: [permissioNames.lb_doc_read.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbdocs" */
                "@/views/admin/AdminLbDocs.vue"
              )
          },
          {
            path: "lbdefaulttemp",
            name: "lbdefaulttemp",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-file-search-outline",
                title: () => store.getters["user/naviTitle"].lbDefaultTemp
              },
              perms: [
                permissioNames.lb_default_temp_read.value,
                permissioNames.lb_default_temp_edit.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbdefaulttemp" */
                "@/views/admin/AdminLbDefaultTempManage.vue"
              )
          },
          {
            path: "lbgrouppattern",
            name: "lbgrouppattern",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-briefcase-variant-outline",
                title: () => store.getters["user/naviTitle"].lbGroupPattern
              },
              perms: [
                permissioNames.lb_group_pattern_read.value,
                permissioNames.lb_group_pattern_edit.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbgrouppattern" */
                "@/views/admin/AdminLbGroupPattern.vue"
              )
          }
        ]
      },
      {
        path: "ap",
        name: "appointpages",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-calendar-month",
            title: () => store.getters["user/naviTitle"].appointPages
          },
          perms: [permissioNames.admin_appoint_edit.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "appointpages" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "doappoint",
            name: "doappoint",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-calendar-month-outline",
                title: () => store.getters["user/naviTitle"].doAppoint
              },
              perms: [permissioNames.admin_appoint_edit.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "doappoint" */
                "@/views/admin/AdminDoAppoint.vue"
              )
          },
          {
            path: "processappoint",
            name: "processappoint",
            props: () => ({ type: "processing" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-calendar-multiple-check",
                title: () => store.getters["user/naviTitle"].processAppoint
              },
              perms: [permissioNames.admin_appoint_edit.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "processappoint" */
                "@/views/admin/AdminAppointment.vue"
              )
          },
          {
            path: "appointhistory",
            name: "appointhistory",
            props: () => ({ type: "finished" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-calendar-clock-outline",
                title: () => store.getters["user/naviTitle"].appointHistory
              },
              perms: [permissioNames.admin_appoint_edit.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "appointhistory" */
                "@/views/admin/AdminAppointment.vue"
              )
          }
        ]
      },
      {
        path: "task",
        name: "taskpages",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-form-select",
            title: () => store.getters["user/naviTitle"].taskPages
          },
          perms: [permissioNames.admin_task_edit.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "taskpages" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "processtask",
            name: "processtask",
            props: () => ({ type: "process" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-folder-multiple-outline",
                title: () => store.getters["user/naviTitle"].processTask
              },
              perms: [permissioNames.admin_task_edit.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "processtask" */
                "@/views/admin/AdminTaskManage.vue"
              )
          },
          {
            path: "mycreatetask",
            name: "mycreatetask",
            props: () => ({ type: "created" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-folder-multiple-outline",
                title: () => store.getters["user/naviTitle"].createdTask
              },
              perms: [permissioNames.admin_task_edit.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "mycreatetask" */
                "@/views/admin/AdminTaskManage.vue"
              )
          }
        ]
      },
      {
        path: "settings",
        name: "settings",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-cog",
            title: () => store.getters["user/naviTitle"].loginSettings
          },
          perms: [permissioNames.admin_user_edit.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            "@/views/admin/AdminSettings.vue"
          )
      }
    ]
  }
];
