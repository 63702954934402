export const defaultAppLoginTitle = "心理测评系统 3.0";

export const nodeTypes = {
  super: {
    value: "super"
  },
  client: {
    value: "client"
  },
  dept: {
    value: "dept"
  }
};

export const personInputTypes = {
  search: {
    value: "search",
    text: "搜索导入的被试者"
  },
  create: {
    value: "create",
    text: "新建被试者"
  }
};

export const permissioNames = {
  user_login: {
    value: "user_login"
  },
  //#region 案例管理界面
  case_read: {
    value: "case_read"
  },
  case_edit: {
    value: "case_edit"
  },
  case_export: {
    value: "case_export"
  },
  case_grouprpt_read: {
    value: "case_grouprpt_read"
  },
  case_rpt_edit: {
    value: "case_rpt_edit"
  },
  //#endregion

  //#region 被试人员管理页面
  person_read: {
    value: "person_read"
  },
  person_edit: {
    value: "person_edit"
  },
  person_import: {
    value: "person_import"
  },
  //#endregion

  //批量下载报告
  case_bulkrpt: {
    value: "case_bulkrpt"
  },

  //#region 测评单位管理
  node_edit: {
    value: "node_edit"
  },
  node_read: {
    value: "node_read"
  },
  node_tstctrl: {
    value: "node_tstctrl"
  },
  node_rpt_sign: {
    value: "node_rpt_sign"
  },
  node_fieldconfig: {
    value: "node_fieldconfig"
  },
  //#endregion

  //#region 团体测评
  group_sca_edit: {
    value: "group_sca_edit"
  },
  group_sca_read: {
    value: "group_sca_read"
  },
  //#endregion
  //#region 开放测评
  open_sca_edit: {
    value: "open_sca_edit"
  },
  open_sca_read: {
    value: "open_sca_read"
  },
  //#endregion
  //#region 个人测评
  indv_sca_edit: {
    value: "indv_sca_edit"
  },
  indv_sca_read: {
    value: "indv_sca_read"
  },
  //#endregion

  //#region 量表
  lb_read: {
    value: "lb_read"
  },
  lb_edit: {
    value: "lb_edit"
  },
  lb_doc_read: {
    value: "lb_doc_read"
  },
  lb_alert_read: {
    value: "lb_alert_read"
  },
  lb_alert_edit: {
    value: "lb_alert_edit"
  },
  lb_default_temp_read: {
    value: "lb_default_temp_read"
  },
  lb_default_temp_edit: {
    value: "lb_default_temp_edit"
  },
  lb_report_temp_edit: {
    value: "lb_report_temp_edit"
  },
  lb_report_temp_read: {
    value: "lb_report_temp_read"
  },
  case_import: {
    value: "case_import"
  },
  lb_group_pattern_read: {
    value: "lb_group_pattern_read"
  },
  lb_group_pattern_edit: {
    value: "lb_group_pattern_edit"
  },
  //#endregion

  admin_user_edit: {
    value: "admin_user_edit"
  },

  admin_data_dashboard: {
    value: "admin_data_dashboard"
  },

  admin_appoint_edit: {
    value: "admin_appoint_edit"
  },
  admin_task_edit: {
    value: "admin_task_edit"
  }
};
